import { AsyncPipe } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { AuthProvider } from '../providers/IAuthProvider';

/**
 * @title Login popup dialog
 */
@Component({
  imports: [AsyncPipe],
  selector: 'gk-notify-before-logout',
  standalone: true,
  styleUrls: ['NotifyBeforeLogout.scss'],
  templateUrl: 'NotifyBeforeLogout.html',
})
export class NotifyBeforeLogoutComponent implements OnInit, OnDestroy {
  private _interval;
  private _logoutTimestamp: Date;
  private modalService = inject(BsModalService);

  @Input() logoutTimestamp: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _logoutRemainingSecounds: number;
  authProvider = inject(AuthProvider);
  bsModalRef = inject(BsModalRef);
  isAuthenticated$: Subject<boolean>;

  close() {
    this.modalService.setDismissReason('close');
    this.bsModalRef.hide();
  }

  continue() {
    this.modalService.setDismissReason('continue');
    this.bsModalRef.hide();
  }

  goToHome() {
    this.modalService.setDismissReason('goToHome');
    this.bsModalRef.hide();
  }

  login() {
    this.modalService.setDismissReason('login');
    this.bsModalRef.hide();
  }

  logout() {
    this.modalService.setDismissReason('logout');
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    clearInterval(this._interval);
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.authProvider.isAuthenticated$;
    const now = new Date();
    this._logoutTimestamp = new Date(this.logoutTimestamp);
    this._logoutRemainingSecounds = Math.floor((this._logoutTimestamp.getTime() - now.getTime()) / 1000);
    this._interval = setInterval(() => {
      this.reduceRemainingTime();
    }, 1000);
  }

  reduceRemainingTime() {
    this._logoutRemainingSecounds = this._logoutRemainingSecounds - 1;
    if (this._logoutRemainingSecounds < 1) {
      const returnUrl = location.search !== '' ? location.pathname + location.search : location.pathname;
      this.authProvider.signOut(returnUrl);
      clearInterval(this._interval);
    }
  }
}
