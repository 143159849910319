<div class="modal-header">
  <h4 class="modal-title pull-left">Upps! Noe gikk galt.</h4>
</div>
<div class="modal-body">
  <p>Vi kunne ikke logge deg inn.</p>
  <p>Feilen er logget.</p>
  <p>Det kan være flere årsaker til dette. Vennligst prøv igjen.</p>
</div>
<div class="modal-footer">
  <button (click)="hide()" class="btn btn-gk-warning" type="button">Lukk</button>
</div>
