import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from '@config/api.config';
import { MapCanvasService } from '@services/map-canvas.service';
import { UserService } from '@services/user.service';
import { SomethingWhentWrongDialogComponent } from 'app/auth/error/SomethingWhentWrongDialog';
import { NotifyBeforeLogoutComponent } from 'app/auth/notifyBeforeLogout/NotifyBeforeLogout';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserData } from '../dto/UserData';

export class SignInState {
  canvasHash: string;
  returnUrl: string;

  constructor(url: string, hash: string) {
    this.returnUrl = url;
    this.canvasHash = hash;
  }
}

export type ISignInResponse = {
  authorizationRequestUri: string;
  codeVerifier: string;
  errorMessage: string;
  isSuccess: boolean;
};

export type IGetTokenResponse = {
  errorMessage: string;
  idToken: string;
  isSuccess: boolean;
  isValidToken: boolean;
  nibioToken: string;
  refreshToken: string;
  userData: UserData;
  userExists: boolean;
  userIdentifier: string;
};

export type IValidateTokenResponse = {
  errorMessage: string;
  expirationTime: number;
  isSuccess: boolean;
  isValidUserToken: boolean;
  userData: UserData;
};

export type ISignOutResponse = {
  errorMessage: string;
  isSuccess: boolean;
  url: string;
  userWasSignedOut: boolean;
};

@Injectable({
  providedIn: 'root',
  useClass: BackendAuthClient,
})
export class BackendAuthClient {
  private document = inject<Document>(DOCUMENT);
  private httpClient = inject(HttpClient);
  private interval: any;
  private isAuthenticated = new BehaviorSubject<boolean>(false);
  private mapCanvas = inject(MapCanvasService);
  private modalService = inject(BsModalService);

  private nibioToken = new BehaviorSubject<string>(localStorage.getItem('nibioToken'));
  private router = inject(Router);
  private urlBackendService: string = ApiConfig.proxiedBackendUrl;
  private userService = inject(UserService);

  bsModalRef: BsModalRef;
  idTokenValue: string;
  isAuthenticatedValue: boolean;
  nibioTokenValue: string;
  providerName$ = 'BackendAuthClient';

  get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  get nibioToken$(): Observable<string> {
    return this.nibioToken.asObservable();
  }

  constructor() {
    this.modalService?.onHide?.subscribe(result => {
      // Decide which action to take when user interact with NotifyBeforeLogout
      const returnUrl = location.search !== '' ? location.pathname + location.search : location.pathname;
      if (result === 'goToHome') {
        void this.router.navigateByUrl('/');
      } else if (result === 'login') {
        this.signIn(returnUrl);
      } else if (result === 'continue') {
        this.renewToken();
      } else if (result === 'logout') {
        this.signOut(returnUrl);
      }
    });

    this.nibioToken$.subscribe(token => (this.nibioTokenValue = token));
    this.isAuthenticated$.subscribe(isAuth => {
      this.isAuthenticatedValue = isAuth;
      if (isAuth) {
        this.startCheckAuthTimer();
      } else {
        this.stopCheckAuthTimer();
      }
    });
  }

  private getSignOutUrl(returnUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const routingUri = environment.signOutCallbackRoutingUri;
      if (!routingUri) {
        reject('Error: no signout url set. Check the configuration.');
      }
      const routing = 'clientCallbackRoutingUrl=' + routingUri;
      const url = this.urlBackendService + 'signouturl?' + routing;
      if (this.nibioTokenValue) {
        this.httpClient.get<ISignOutResponse>(url, { headers: { state: returnUrl } }).subscribe(
          result => {
            if (result.isSuccess) {
              const redirectUri = result.url;
              if (redirectUri !== null) {
                resolve(redirectUri);
              } else {
                reject();
              }
            } else {
              reject();
            }
          },
          () => {
            this.somethingWhentWrong();
            reject();
          },
        );
      } else {
        reject();
      } // interceptor add token to request headers
    });
  }

  /**
   * Unexpected error in login. Notify user and redirect to home
   */
  private notifyBeforeLogout(autoLogoutTimestamp: number) {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
    };

    const initialState = {
      logoutTimestamp: autoLogoutTimestamp,
    };

    this.bsModalRef = this.modalService.show(
      NotifyBeforeLogoutComponent,
      Object.assign({}, config, { class: 'modal-sm', initialState }),
    );
    // const dialogRef = this.dialog.open(NotifyBeforeLogoutComponent, {data: {logoutTimestamp: autoLogoutTimestamp}});
    // dialogRef.afterClosed().subscribe(result => {
  }

  /**
   * Parse received responseobject from backend gettoken and set local storage
   *
   * @param  {IGetTokenResponse}  response    Response object from "GetToken"
   */
  private saveTokenResponseInLocalStorage(response: IGetTokenResponse): void {
    if (response.nibioToken != null) {
      this.isAuthenticated.next(true);
      const idTokenJson = JSON.parse(response.nibioToken);
      this.nibioToken.next(idTokenJson.nibioToken);

      // localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('nibioToken', idTokenJson.nibioToken);
    }
  }

  /**
   * Call backend to receive validated login URL for this session
   *
   * @return {Promise<string>}                The URL client must be redirected to for login
   */
  private signOutIfNotAuthenticated(response: IValidateTokenResponse) {
    const nowTimeStamp = new Date().getTime(); // Date.now()
    const nextTimeStamp = nowTimeStamp + environment.checkAuthIntervalInMillicecounds;
    const lastActivityTimeStamp =
      localStorage.getItem('lastActivityTimeStamp') != null
        ? parseInt(localStorage.getItem('lastActivityTimeStamp'), 10)
        : nowTimeStamp;
    const lastActivityTimeoutTimeStamp = lastActivityTimeStamp + environment.logoutIfInactiveInMillicecounds;
    const countDownMillisecounds = environment.countDownBeforeLogoutMillisecounds;
    /*
    console.log('Now: ' + new Date(nowTimeStamp).toLocaleTimeString() +
                 ' - Last activity: ' + new Date(lastActivityTimeStamp).toLocaleTimeString() +
                 ' - Token expires: ' + new Date(response.expirationTime).toLocaleTimeString() +
                 ' - Activity expires: ' + new Date(lastActivityTimeoutTimeStamp).toLocaleTimeString()
                );
*/
    // --- If token is invalid or expired then log out local ---
    if (!response.isValidUserToken && this.isAuthenticatedValue) {
      // this.signOutLocal();
      this.notifyBeforeLogout(nowTimeStamp);
    } else if (
      response.expirationTime - nowTimeStamp < environment.checkAuthIntervalInMillicecounds ||
      response.expirationTime - nowTimeStamp < countDownMillisecounds
    ) {
      // --- Token expired: Notify user before logout if less than 2 check intervall before auto logout ---
      this.notifyBeforeLogout(nextTimeStamp);
    } else if (
      lastActivityTimeoutTimeStamp - nowTimeStamp < environment.checkAuthIntervalInMillicecounds ||
      lastActivityTimeoutTimeStamp - nowTimeStamp < countDownMillisecounds
    ) {
      // --- No activity: Notify user before logout if less than 2 check intervall before auto logout ---
      this.notifyBeforeLogout(nextTimeStamp);
    }
  }

  /**
   * Clear local login information
   *
   */
  private signOutLocal(): void {
    this.isAuthenticated.next(false);
    this.nibioToken.next(null);
    this.userService.clearUserData();

    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('codeVerifier');
    localStorage.removeItem('nibioToken');
    localStorage.removeItem('lastActivityTimeStamp');
  }

  /**
   * Unexpected error in login. Notify user and redirect to home
   */
  private somethingWhentWrong() {
    this.bsModalRef = this.modalService.show(SomethingWhentWrongDialogComponent);
    this.modalService.onHide.subscribe(() => {
      void this.router.navigateByUrl('/');
    });
  }

  /**
   * Start timer to check auth (configured interval)
   */
  private startCheckAuthTimer() {
    this.stopCheckAuthTimer();
    this.interval = setInterval(() => {
      this.checkAuthAndSignOutIfNotAuthenticated();
    }, environment.checkAuthIntervalInMillicecounds);

    // -- Initial auth check in case token exist in the store ---
    // this.checkAuthAndSignOutIfNotAuthenticated();
  }

  /**
   * Stop timer to check auth status
   */
  private stopCheckAuthTimer() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  /**
   * Check login state
   *
   */
  checkAuthAndSignOutIfNotAuthenticated(): void {
    if (this.isAuthenticatedValue) {
      const url = this.urlBackendService + 'isUserAuthenticated';
      this.httpClient.get<IValidateTokenResponse>(url).subscribe(response => {
        if (!response.isSuccess) {
          console.log(response.errorMessage);
        }
        this.signOutIfNotAuthenticated(response);
      });
    }
  }

  // @ts-expect-error Needs a rewrite, currently causes: TS7030: Not all code paths return a value
  getSignInUrl(returnUrl: string): Promise<string> {
    if (environment.signInCallbackRoutingUrl === null || environment.signInCallbackRoutingUrl === '') {
      console.log('Error: signInCallbackRoutingUrl not set! Check your configuration');
    }
    const url = this.urlBackendService + 'signIn?clientCallbackRoutingUrl=' + environment.signInCallbackRoutingUrl;
    try {
      const stateHeader = new SignInState(returnUrl, this.mapCanvas.getCanvasHash);
      return new Promise<string>((resolve, reject) => {
        this.httpClient
          .get<ISignInResponse>(url, {
            headers: {
              state: JSON.stringify(stateHeader),
            },
          })
          .subscribe(
            result => {
              if (result.isSuccess) {
                // --- Store codeVerifier from result. (Needed in getToken request later) ---
                localStorage.setItem('codeVerifier', result.codeVerifier);
                const redirectUri = result.authorizationRequestUri;
                if (redirectUri != null) {
                  resolve(redirectUri); // Resolve redirect URL
                } else {
                  reject(null);
                }
              } else {
                console.log('Error getSignInUrl(): ' + result.errorMessage);
                this.somethingWhentWrong();
                reject(null);
              }
            },
            error => {
              console.log('Unexpected error when calling signIn(): ' + error.message);
              this.somethingWhentWrong();
            },
          );
      });
    } catch (err) {
      console.log('Unexpected error in getSignInUrl(): ' + err.message);
      this.somethingWhentWrong();
    }
  }

  renewToken(): void {
    try {
      const getTokenUrl = this.urlBackendService + 'renewToken';
      this.httpClient.get<IGetTokenResponse>(getTokenUrl).subscribe(
        response => {
          if (response.isSuccess && response.isValidToken) {
            // --- Parse token from response and save local storage ---
            this.saveTokenResponseInLocalStorage(response);
          } else {
            console.log(response.errorMessage ?? 'Backed failed when trying to receive token');
            this.somethingWhentWrong();
          }
        },
        error => {
          console.log('Unexpected error when calling getToken():', error.message);
          this.somethingWhentWrong();
        },
      );
    } catch (err) {
      console.log('Unexpected error in signInCallback():', err.message);
      this.somethingWhentWrong();
    }
  }

  /**
   * When user click "login" -> Redirect to the login page
   */
  signIn(returnUrl: string): void {
    this.getSignInUrl(returnUrl).then(redirectUri => {
      this.document.location.href = redirectUri;
    });
  }

  /**
   * After successfully login this callback is recived to notify user to get token
   *
   * @param  {string}          code    Unique code for this login request (received from ID porten)
   * @param {SignInState}      state
   */
  signInCallback(code: string, state: SignInState): void {
    try {
      if (code) {
        // --- Get codeVerifier received from signIn ---
        const codeVerifier = localStorage.getItem('codeVerifier');
        if (!codeVerifier) {
          console.log('Unable to request token. Code verification not available!');
          this.somethingWhentWrong();
        } else {
          const getTokenUrl =
            this.urlBackendService +
            'getToken?clientCallbackRoutingUrl=' +
            environment.signInCallbackRoutingUrl +
            '&code=' +
            code +
            '&codeVerifier=' +
            codeVerifier;
          this.httpClient.get<IGetTokenResponse>(getTokenUrl).subscribe(
            response => {
              let redirectUrl = state.returnUrl;
              if (response.isSuccess && response.isValidToken) {
                // --- Parse token from response and save local storage ---
                this.saveTokenResponseInLocalStorage(response);
                if (!response.userExists) {
                  redirectUrl = '/bruker-registrering';
                } else {
                  this.userService.refreshUserData();
                }
              } else {
                console.log(response.errorMessage ?? 'Backed failed when trying to receive token');
                this.somethingWhentWrong();
              }

              if (state.canvasHash) {
                this.mapCanvas.setHash(state.canvasHash);
              }

              void this.router.navigateByUrl(redirectUrl, {
                replaceUrl: true,
                state: { returnUrl: state },
              });
            },
            error => {
              console.log('Unexpected error when calling getToken():', error.message);
              this.somethingWhentWrong();
            },
          );
        }
      } // else this.router.navigateByUrl('/');
    } catch (err) {
      console.log('Unexpected error in signInCallback():', err.message);
      this.somethingWhentWrong();
    }
  }

  signOut(returnUrl = '/search'): void {
    this.getSignOutUrl(returnUrl).then(
      redirectUri => {
        this.document.location.href = redirectUri;
      },
      error => {
        if (error) {
          console.log(error);
        }
        this.signOutLocal();
        this.document.location.href = returnUrl;
      },
    );
  }

  signOutCallback(state: string): void {
    const signOutUrl = this.urlBackendService + 'signout';
    this.httpClient.get<ISignOutResponse>(signOutUrl).subscribe(
      response => {
        if (response.isSuccess) {
          // if(response.isSuccess && response.userWasSignedOut) {
          this.signOutLocal();
          void this.router.navigateByUrl(state, {
            replaceUrl: true,
            state: { returnUrl: state },
          });
        } else {
          this.somethingWhentWrong();
        }
      },
      error => {
        console.log('Unexpected error when calling signOut():', error.message);
        this.somethingWhentWrong();
      },
    );
  }
}
