import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * @title Login popup dialog
 */
@Component({
  selector: 'gk-something-whent-wrong',
  standalone: true,
  styleUrls: ['SomethingWhentWrongDialog.scss'],
  templateUrl: 'SomethingWhentWrongDialog.html',
})
export class SomethingWhentWrongDialogComponent {
  bsModalRef = inject(BsModalRef);

  hide() {
    this.bsModalRef.hide();
  }
}
